import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import useI18n from '../utils/useI18n';
import SEO from '../components/seo/SEO';
import FeaturedTestimonial from '../components/FeaturedTestimonial';
import ContactButton from '../components/ContactButton';
import Comments from '../components/Comments';
import '../styles/components/_components.resort.scss';
import useSiteMetadata from '../utils/useSiteMetadata';
import Content from '../components/Content';

const Resort = ({ pageContext: { switchLang }, data: { record }, location }) => {
  const { t } = useI18n();
  const { siteUrl } = useSiteMetadata();
  const {
    Hotel,
    featuredImg,
    childrenHtmlRehype: [{ htmlAst: content }],
  } = record;
  const fields = [
    'legal_name',
    'sales_locations',
    'collections',
    'rental_companies',
    'representatives',
  ];
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={record.Hotel.titulo}
        description={record.Hotel.description}
        keywords={record.Hotel.keywords}
        image={featuredImg ? featuredImg.childImageSharp.fixed : null}
        pathname={location.pathname}
        webpage
        breadCrumbs={[
          {
            name: t('menu.blacklist'),
            url: `${siteUrl}/${t('url.blacklist')}`,
          },
          {
            name: record.Hotel.titulo,
            url: `${siteUrl}/${location.pathname}`,
          },
        ]}
      />
      <div className="o-wrapper c-resort">
        <h1 className="u-margin-bottom-tiny">{Hotel.titulo}</h1>
        <h2 className="o-callout">{Hotel.subtitulo}</h2>
        <div className="c-resort__pic">
          {featuredImg && (
            <Img fluid={featuredImg.childImageSharp.fluid} fadein={false} alt={Hotel.titulo} />
          )}
        </div>
        <div className="c-resort__content">
          <dl>
            {fields
              .filter((field) => Hotel[field])
              .map((field) => (
                <span key={field}>
                  <dt>{t(`Hotel.attributes.${field}`)}</dt>
                  <dd>{Hotel[field]}</dd>
                </span>
              ))}
          </dl>
          <Content htmlAst={content} />
        </div>
      </div>
      <FeaturedTestimonial />
      <Comments id={Hotel.id} total={Hotel.comments} type="Hotel" />
      <ContactButton section />
    </Layout>
  );
};

export const query = graphql`
  query HotelQuery($id: String!) {
    record: hotel(id: { eq: $id }) {
      Hotel {
        collections
        created
        description
        detalles
        id
        keywords
        legal_name
        rental_companies
        representatives
        sales_locations
        slug
        slug_es_mx
        slug_en_us
        subtitulo
        title
        titulo
        comments
      }
      childrenHtmlRehype {
        htmlAst
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 460, quality: 60) {
            ...GatsbyImageSharpFluid
          }
          fixed(quality: 80) {
            src
            width
            height
          }
        }
      }
    }
  }
`;

export default Resort;
